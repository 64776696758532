angular.module('kljDigitalLibraryApp')
    .service('guestLoginService', ["$q", "common", "appConfig", "$localStorage",
        function($q, common, appConfig, $localStorage) {
            var token = "";
            this.addToken = function(tokenValue) {
                token = tokenValue;
            }
            this.getToken = function() {
                return token;
            }

        }
    ]);